// this file exists solely to deal with compatibility inconsistencies between `process.env` and `import.meta.env` in shared code
let EnvironmentVariables;
if (typeof process !== 'undefined' && process.env) {
  EnvironmentVariables = process.env;
} else if (import.meta?.env) {
  EnvironmentVariables = import.meta.env;
} else {
  throw new Error('Unable to resolve env vars');
}

export default EnvironmentVariables;
